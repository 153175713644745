<template>
  <div class="layout-service">
    <!--s: Service Menu-->
    <ServiceMenu v-if="!isPhone()" />
    <!--e: Service Menu-->

    <div class="layout-service-content">
      <div class="service-box">
        <h1 class="title" v-if="!isPhone()"></h1>
        <h1 class="title" v-if="isPhone()">
        {{ $t("footer.contactUs") }}
        <a href="javascript:" class="close" @click="onClose"></a>
        </h1>
        <div class="subtitle2">{{ $t("footer.contactUs1") }}</div>
        <h2 class="subtitle2" v-html="$t('footer.contactUs2')"></h2>
        <h2 class="subtitle2" v-html="$t('footer.contactUs3')"></h2>
        <div class="subtitle2" v-html="$t('footer.contactUs4')"></div>
        <h2 class="subtitle2">{{ $t("footer.contactUs5") }}</h2>
      </div>
    </div>
  </div>
</template>
<script>
import ContactUs from "./contactUs";
export default ContactUs;
</script>
<style lang="less">
@import "contactUs";
</style>
